import {AfterViewChecked, Component, isDevMode} from '@angular/core';
import versions from '../_versions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  isDev = isDevMode();
  version = versions;

  ngAfterViewChecked(): void {
    if (document.getElementById('c-header')?.classList.contains('no-mainstage')) {
      document.body.style.paddingTop = '8rem';
    }
  }
}
