import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {GlobalState} from '../../state/state';
import {RouterReducerState, SerializedRouterStateSnapshot} from '@ngrx/router-store';

@Component({
  selector: 'app-lang-changer',
  templateUrl: './lang-changer.component.html',
  styleUrls: ['./lang-changer.component.scss']
})
export class LangChangerComponent implements OnInit {

  @Input() path: string;
  @Input() lang: string;
  @Input() editable: any;


  constructor() { }

  ngOnInit(): void {
    document.getElementById(this.lang)?.classList.add('active');
  }

}
