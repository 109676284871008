import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {getErrorMessages} from '../../../../helpers/helpers';
import {Translations} from '../../../../models/models';
import {TranslatePipe} from '../../../../pipes/translate.pipe';

@Component({
  selector: 'app-c-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent {

  @Input() label: string;
  @Input() id: string;
  @Input() type: string;
  @Input() formControl: FormControl;
  @Input() translations: Translations;

  constructor(private translatePipe: TranslatePipe) { }

  onChange: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }

  showError(): string {
    const errors = getErrorMessages(this.formControl, this.translatePipe, this.translations);
    return errors?.length ? errors[0] : '';
  }
}
