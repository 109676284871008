import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss']
})
export class SocialButtonsComponent {

  constructor() { }

}
