import {Component, Input, OnInit} from '@angular/core';
import {Translations} from '../../models/models';

@Component({
  selector: 'app-c-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() aboutTitle: string;
  @Input() aboutText: string;
  @Input() aboutLocation: string;
  @Input() aboutQuestion: string;
  @Input() aboutTelephone: string;
  @Input() updateTitle: string;
  @Input() socialsTitle: string;
  @Input() socialsText: string;
  @Input() editable: any;

  constructor() { }

}
