<div class="c-input-text--wrapper" data-test="textfield">
  <label [for]="id" class="input-text-label" data-test="textfield-label">{{ label }}</label>
  <input [type]="type"
         class="input-text"
         [name]="id"
         [formControl]="formControl"
         (change)="onChange()"
         [id]="id"
         data-test="textfield-input">
  <span *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)" class="input-text__error-message"data-test="textfield-error">
  {{ showError() }}
  </span>
</div>

