import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {GlobalState} from '../state/state';
import {LangGuardActions} from '../state/app/actions';
import {Languages} from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {
  constructor(private store: Store<GlobalState>, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.store.select(appState => appState.app.language).pipe(
      map(lang => {
        if (Object.values(Languages).includes(next.paramMap.get('lang'))) {
          return true;
        } else {
          this.router.navigateByUrl(state.url.replace(`/${next.paramMap.get('lang')}`, '/en'));
        }
      })
    );
  }
}
