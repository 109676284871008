import {AbstractControl, FormControl} from '@angular/forms';
import {Translations} from '../models/models';
import {TranslatePipe} from '../pipes/translate.pipe';

const EMAIL_REGEX = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/;
const TELEPHONE_REGEX = /^[0-9 \.,\-\+\(\)\/]{7,20}$/;
const VALID_CHARS_REGEX = /[a-zA-Z0-9'{}[\]\\;':",./?!@#$%&*()_+=-]$/;
const NUMBERS_REGEX = /^[0-9]*$/;

export function getErrorMessages(formControl: FormControl, translatePipe: TranslatePipe, translations: Translations): string[] {
  if (formControl.invalid && formControl.errors && formControl.touched) {
    return Object.keys(formControl.errors).map(error => translatePipe.transform(translations, `gataweb.form.errors.validation.${error}`));
  }
}

export function getErrorMessageRadio(formControl: AbstractControl, translatePipe: TranslatePipe, translations: Translations): string {
  if (formControl.errors.required) {
    return translatePipe.transform(translations, `gataweb.form.errors.validation.option`);
  }
}

export function validateEmail(control: AbstractControl): { email: true } | null {
  if (!control.value?.match(/^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/)) {
    return { email: true };
  }
  return null;
}

export function validateTelephone(control: AbstractControl): { telephone: true } | null {
  if (!control.value?.match(/^[0-9 \.,\-\+\(\)\/]{7,20}$/)) {
    return { telephone: true };
  }
  return null;
}

export function validateValidChars(control: AbstractControl): { chars: true } | null {
  if (!control.value?.match(/[a-zA-Z0-9_ '{}[\]\\;':",./?!@#$%&*()_+=-]$/)) {
    return { chars: true };
  }
  return null;
}

export function validateValidNumbers(control: AbstractControl): { numbers: true } | null {
  if (!control.value?.match(/^[0-9]*$/)) {
    return { numbers: true };
  }
  return null;
}

export function translate(translations: Translations, key: string): string {
  return translations && translations.hasOwnProperty(key) ? translations[key] : key;
}
