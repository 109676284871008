import { Pipe, PipeTransform } from '@angular/core';
import {Translations} from '../models/models';
import {translate} from '../helpers/helpers';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  transform(translations: Translations, key: string): string {
    return translate(translations, key);
  }
}

