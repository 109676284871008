import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Translations} from '../models/models';
import {Observable} from 'rxjs';
import {getTranslationsPath} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) {
  }

  getTranslation(language: string): Observable<Translations> {
    return this.http.get<Translations>(getTranslationsPath(language));
  }
}
