import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LangGuard} from './guards/lang.guard';
import {ThankyYouContainerComponent} from './pages-containers/thanky-you-container/thanky-you-container.component';
import {DevModeGuard} from './guards/dev-mode.guard';
import {HomeComponent} from './pages/home/home.component';

const routes: Routes = [
  {
    path: 'dev-content/:page',
    component: HomeComponent,
    canActivate: [
      DevModeGuard
    ]
  },
  {
    path: ':lang',
    canActivate: [
      LangGuard,
    ],
    children: [
      {
        path: '', redirectTo: 'products', pathMatch: 'full'
      },
      {
        path: 'products', component: HomeComponent
      },
      {
        path: 'contact-us', component: HomeComponent
      },
      {
        path: 'thank-you', component: ThankyYouContainerComponent
      },
      {
        path: 'not-found', component: HomeComponent
      },
      {
        path: 'websites', component: HomeComponent
      },
      {
        path: 'mobile-app', component: HomeComponent
      },
      {
        path: 'hosting', component: HomeComponent
      },
      {
        path: 'about-us', component: HomeComponent
      },
      {
        path: 'blog', component: HomeComponent
      },
      {
        path: '**', redirectTo: 'not-found'
      }
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '', redirectTo: '/en/products', pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule {
}
