import {Component, Input, OnInit} from '@angular/core';
import {ArticleImageComponent} from './elements/article-image/article-image.component';
import {ArticleTextComponent} from './elements/article-text/article-text.component';
import {ArticleTitleComponent} from './elements/article-title/article-title.component';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {
  @Input() contents: any;
  @Input() editable: any;
  componentsArticle = { articleImage: ArticleImageComponent, articleText: ArticleTextComponent, articleTitle: ArticleTitleComponent };
}
