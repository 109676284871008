import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {getErrorMessages} from '../../../../helpers/helpers';
import {TranslatePipe} from '../../../../pipes/translate.pipe';
import {Translations} from '../../../../models/models';

@Component({
  selector: 'app-c-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent {
  maxlength = 250;

  @Input() label: string;
  @Input() id: string;
  @Input() remaining: string;
  @Input() formControl: FormControl;
  @Input() translations: Translations;

  constructor(private translatePipe: TranslatePipe) {
  }

  onChange: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
    if (val !== this.formControl.value) {
      this.formControl.setValue(val);
      this.onChange(val);
    }
  }

  getValueLength(): number {
    return this.formControl.value.length;
  }

  showError(): string {
    const errors = getErrorMessages(this.formControl, this.translatePipe, this.translations);
    return errors?.length ? errors[0] : '';
  }
}
