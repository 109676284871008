import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-article-image',
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss']
})
export class ArticleImageComponent implements OnInit {

  @Input() imageSrc: any;
  @Input() editable: any;
  @Input() imageDescription: string;
  @Input() smaller: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
