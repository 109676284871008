import {Translations} from '../../models/models';

export interface AppState {
  language: string;
  translations: Translations;
}

export const initialAppState: AppState = {
  language: 'en',
  translations: {}
};
