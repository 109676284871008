<div class="c-form constrainer" [appStoryblok]="editable">
  <div class="c-form--wrapper">
    <div class="c-form--content">
      <app-c-input-text [label]="firstname"
                        id="firstname"
                        [translations]="validationTranslationsMapped"
                        [formControl]="contactForm.get('firstname')"
                        type="text">
      </app-c-input-text>
      <app-c-input-text [label]="lastname"
                        id="lastname"
                        [translations]="validationTranslationsMapped"
                        [formControl]="contactForm.get('lastname')"
                        type="text">
      </app-c-input-text>
      <app-c-input-text [label]="telephone"
                        id="telephone"
                        [translations]="validationTranslationsMapped"
                        [formControl]="contactForm.get('telephone')"
                        type="number">
      </app-c-input-text>
      <app-c-input-text [label]="email"
                        id="email"
                        [translations]="validationTranslationsMapped"
                        [formControl]="contactForm.get('email')"
                        type="email">
      </app-c-input-text>
      <app-c-input-radio
        [label]="productsTitle"
        [options]="
    [
      {label: website, value: 'Website', controlName: 'product'},
      {label: mobile, value: 'App', controlName: 'product'},
      {label: hosting, value: 'Hosting', controlName: 'product'}
    ]"
        [formGroup]="productForm"
        [translations]="validationTranslationsMapped"
      ></app-c-input-radio>
      <app-c-textarea [label]="desc"
                      [formControl]="contactForm.get('description')"
                      [remaining]="remaining"
                      [translations]="validationTranslationsMapped"
                      id="description"></app-c-textarea>
    <div class="c-form--buttons">
      <div class="reset">
        <app-c-button-secondary [label]="reset" (click)="resetForm()"></app-c-button-secondary>
      </div>
      <div class="submit">
        <app-c-button-primary [label]="send" (click)="submitForm()"></app-c-button-primary>
      </div>
    </div>
  </div>
  </div>
</div>
