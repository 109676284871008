import {Component, Input, OnInit} from '@angular/core';
import {RadioElement} from '../../../../models/models';

@Component({
  selector: 'app-c-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent {

  @Input()
  label: string;

  @Input()
  options: RadioElement[];

  constructor() { }

}
