import {initialAppState} from './app.state';
import {createReducer, on} from '@ngrx/store';
import {LangGuardActions} from './actions';

export const reducer = createReducer(
  initialAppState,
  on(LangGuardActions.changeLanguage, (state, {language}) => ({...state, language})),
  on(LangGuardActions.loadTranslations, state => ({...state})),
  on(LangGuardActions.loadTranslationsSuccess, (state, {translations}) => ({...state, translations})),
  on(LangGuardActions.loadTranslationsFailed, state => ({...state})),
);
