import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslationService} from '../../services/translation.service';
import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {GlobalState} from '../state';
import {of} from 'rxjs';
import {LangGuardActions} from './actions';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {

  loadTranslations = createEffect(() => {
    return this.actions.pipe(
      ofType(LangGuardActions.loadTranslations),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.select(state => state)))
      ),
      switchMap(([action, state]) => {
        return this.translationService.getTranslation(state.app.language).pipe(
          map((translations) => LangGuardActions.loadTranslationsSuccess({translations})),
          catchError(() => of(LangGuardActions.loadTranslationsFailed()))
        );
      })
    );
  });

  constructor(private store: Store<GlobalState>,
              private actions: Actions,
              private translationService: TranslationService) {
  }
}
