<app-c-header active="contact-us" [lang]="lang" [translations]="translations"></app-c-header>
<div class="title">
  <app-title
    [header]="translations | translate: 'gataweb.thanks.title.contact.title'"
    [paragraph]="translations | translate: 'gataweb.thanks.title.contact.paragraph'"
  >
  </app-title>
</div>
<div class="information-wrapper">
  <div class="information">
    <h1>{{ translations | translate: 'gataweb.thanks.information.info' }}</h1>
    <strong>{{ translations | translate: 'gataweb.thanks.information.firstname' }}</strong>
    <p>{{ message.firstname }}</p>
    <strong>{{ translations | translate: 'gataweb.thanks.information.lastname' }}</strong>
    <p>{{ message.lastname }}</p>
    <strong>{{ translations | translate: 'gataweb.thanks.information.telephone' }}</strong>
    <p>{{ message.telephone }}</p>
    <strong>{{ translations | translate: 'gataweb.thanks.information.email' }}</strong>
    <p>{{ message.email }}</p>
    <strong>{{ translations | translate: 'gataweb.thanks.information.product' }}</strong>
    <p>{{ message.product }}</p>
    <strong>{{ translations | translate: 'gataweb.thanks.information.description' }}</strong>
    <p>{{ message.description }}</p>
  </div>
</div>
<app-lang-changer [lang]="lang" path="thank-you"></app-lang-changer>
