import {PageComponent} from './page/page.component';
import {TitleComponent} from './title/title.component';
import {HeaderComponent} from './header/header.component';
import {MainStageComponent} from './main-stage/main-stage.component';
import {ContentComponent} from './content/content.component';
import {ContentRightComponent} from './content-right/content-right.component';
import {ProductsComponent} from './products/products.component';
import {FooterComponent} from './footer/footer.component';
import {InfoComponent} from './info/info.component';
import {FormComponent} from './form/form.component';
import {ArticleComponent} from './article/article.component';
import {ArticleImageComponent} from './article/elements/article-image/article-image.component';
import {ArticleTextComponent} from './article/elements/article-text/article-text.component';
import {ArticleTitleComponent} from './article/elements/article-title/article-title.component';
import {LangChangerComponent} from './lang-changer/lang-changer.component';
import {GridComponent} from './grid/grid.component';
import {LinkComponent} from './link/link.component';
import {IconComponent} from './icon/icon.component';
import {ColorTeaserComponent} from './color-teaser/color-teaser.component';
import {BlogArticleComponent} from './blog-article/blog-article.component';

const Components = {
  page: PageComponent,
  title: TitleComponent,
  header: HeaderComponent,
  mainStage: MainStageComponent,
  content: ContentComponent,
  contentRight: ContentRightComponent,
  products: ProductsComponent,
  footer: FooterComponent,
  info: InfoComponent,
  form: FormComponent,
  article: ArticleComponent,
  blogArticle: BlogArticleComponent,
  articleImage: ArticleImageComponent,
  articleText: ArticleTextComponent,
  articleTitle: ArticleTitleComponent,
  langChanger: LangChangerComponent,
  grid: GridComponent,
  link: LinkComponent,
  icon: IconComponent,
  colorTeaser: ColorTeaserComponent
};

const ArticleSubComponents = {
  articleImage: ArticleImageComponent,
  articleText: ArticleTextComponent,
  articleTitle: ArticleTitleComponent
};

export { Components, ArticleSubComponents };
