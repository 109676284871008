import {Component, Input, OnInit} from '@angular/core';
import {StoryblokService} from '../../services/storyblok.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  components: any;

  constructor(private storyblok: StoryblokService) {
    import('src/app/components/index').then(cp => {
      this.components = cp.Components;
    });
  }

  @Input() body: any[];
  // tslint:disable-next-line:variable-name
  @Input() _editable: any;

  ngOnInit(): void {}

}
