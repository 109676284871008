import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-c-main-stage',
  templateUrl: './main-stage.component.html',
  styleUrls: ['./main-stage.component.scss']
})
export class MainStageComponent {

  @Input() image: any;
  @Input() markupInfo: any;
  @Input() purpleVariant = false;
  @Input() label: string;
  @Input() title: string;
  @Input() editable: any;

  constructor(public sanitizer: DomSanitizer) { }

}
