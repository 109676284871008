<div class="c-header" [ngClass]="{'no-mainstage': isMainstageActive}" id="c-header" data-test="header" [appStoryblok]="editable">
  <div class="c-header-logo-container" data-test="header-logo">
    <img src="../../../assets/logo-gataweb-big.png" class="logo" alt="logo" data-test="header-image">
  </div>
  <div class="navs">
    <div class="navi">
      <a href="{{lang}}/products" id="products" class="nav-link">{{ productsLabel }}</a>
      <div class="navs-subnav">
        <a href="{{lang}}/websites" id="websites">{{ websitesLabel }}</a>
        <a href="{{lang}}/mobile-app" id="mobile">{{ mobileLabel }}</a>
        <a href="{{lang}}/hosting" id="hosting">{{ hostingLabel }}</a>
      </div>
    </div>
    <div class="navi">
      <a href="{{lang}}/about-us" id="about-us" class="nav-link">{{ aboutLabel }}</a>
      <div class="navs-subnav">
        <a href="{{lang}}/blog" id="blog">{{ blogLabel }}</a>
      </div>
    </div>
    <div class="navi">
      <a href="{{lang}}/contact-us" id="contact-us" class="nav-link">{{ contactLabel }}</a>
    </div>
  </div>
  <app-c-nav-overlay [active]="active"
                     [lang]="lang"
                     [translations]="translations"
                     [aboutLabel]="aboutLabel"
                     [blogLabel]="blogLabel"
                     [contactLabel]="contactLabel"
                     [hostingLabel]="hostingLabel"
                     [mobileLabel]="mobileLabel"
                     [productsLabel]="productsLabel"
                     [websitesLabel]="websitesLabel"
  ></app-c-nav-overlay>
</div>
