import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {Store} from '@ngrx/store';
import {GlobalState} from '../state/state';

@Injectable({
  providedIn: 'root'
})
export class DevModeGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isDevMode()) {
      return true;
    } else {
      this.router.navigateByUrl('');
      return false;
    }
  }
}
