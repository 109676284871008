<div class="c-textarea--wrapper">
  <label [for]="id" class="textarea-label">{{ label }}</label>
  <textarea class="textarea"
            [id]="id"
            [name]="id"
            rows="4"
            cols="50"
            maxlength="250"
            (change)="onChange()"
            [formControl]="formControl"></textarea> <br>
  <div class="textarea__messages">
    <span class="textarea-remaining">{{ maxlength - getValueLength() }} {{ remaining }}</span>
    <span *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)" class="textarea__error-message">
    {{ showError() }}
    </span>
    </div>
</div>
