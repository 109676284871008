<div class="c-media">
  <div class="info">
    <h2>The newest release</h2>
    <p>See this track on Youtube</p>
  </div>
  <div class="video">
    <iframe class="video-frame"
            width="100%"
            height="250"
            src="https://www.youtube.com/embed/1xPq5oHbexk"
            frameborder="0"
            allow="accelerometer;
            autoplay; encrypted-media;
            gyroscope; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>
