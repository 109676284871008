import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-color-teaser',
  templateUrl: './color-teaser.component.html',
  styleUrls: ['./color-teaser.component.scss']
})
export class ColorTeaserComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  image1: any;

  @Input()
  text: string;

  @Input()
  image2: any;

  @Input()
  editable: any;

  constructor() { }

  ngOnInit(): void {
  }

}
