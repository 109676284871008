export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.6.48',
    name: 'gataweb',
    versionDate: '2022-04-02T08:42:01.540Z',
};
export default versions;
