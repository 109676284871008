<div id="myNav" class="overlay" data-test="overlay">
  <div class="closebtn" (click)="closeNav()" data-test="header-close">
    <svg-icon key="close" color="#280972" fontSize="24px" class="menu"></svg-icon>
  </div>
  <div class="overlay-content">
    <div class="nav-item" (click)="toggleProductSubMenu()">
      <div class="nav-item-wrap">
        <a href="{{ lang }}/products" id="o-products">{{ productsLabel }}</a>
        <div class="nav-item-plus" id="p-plus-minus">
          <svg-icon [key]="!submenuProductActive ? 'plus' : 'minus'" color="#280972" fontSize="18px" class="menu"></svg-icon>
        </div>
      </div>
    </div>
    <div  [ngClass]="{ 'is-active': submenuProductActive }" class="nav-item-sub nav-item" [hidden]="!submenuProductActive">
      <a href="{{ lang }}/websites" id="o-websites">{{ websitesLabel }}</a>
      <a href="{{ lang }}/mobile-app" id="o-mobile">{{ mobileLabel }}</a>
      <a href="{{ lang }}/hosting" id="o-hosting">{{ hostingLabel }}</a>
    </div>
    <div class="nav-item" (click)="toggleAboutSubMenu()">
      <div class="nav-item-wrap">
        <a href="{{ lang }}/about-us" id="o-about-us">{{ aboutLabel }}</a>
        <div class="nav-item-plus" id="a-plus-minus">
          <svg-icon [key]="!submenuAboutActive ? 'plus' : 'minus'" color="#280972" fontSize="18px" class="menu"></svg-icon>
        </div>
      </div>
    </div>
    <div  [ngClass]="{ 'is-active': submenuAboutActive }" class="nav-item-sub nav-item" [hidden]="!submenuAboutActive">
      <a href="{{ lang }}/blog" id="o-blog">{{ blogLabel }}</a>
    </div>
    <div class="nav-item">
      <a href="{{ lang }}/contact-us" id="o-contact-us">{{ contactLabel }}</a>
    </div>
  </div>
  <div class="bottom">
    <p>© 2020 Gataweb. All Rights Reserved.</p>
  </div>
</div>

<div class="c-header-menu-trigger" data-test="header-hamburger">
  <svg-icon key="menu" color="#280972" fontSize="32px" class="menu" (click)="openNav()"></svg-icon>
</div>
