import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Translations} from '../../models/models';

@Component({
  selector: 'app-c-nav-overlay',
  templateUrl: './nav-overlay.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./nav-overlay.component.scss']
})
export class NavOverlayComponent implements OnInit {

  @Input() active: string;
  @Input() translations: Translations;
  @Input() lang: string;
  @Input() productsLabel: string;
  @Input() websitesLabel: string;
  @Input() mobileLabel: string;
  @Input() hostingLabel: string;
  @Input() aboutLabel: string;
  @Input() blogLabel: string;
  @Input() contactLabel: string;
  submenuProductActive = false;
  submenuAboutActive = false;

  constructor() { }

  ngOnInit(): void {
    if (this.active) {
      this.active.split('/').forEach((path) => {
        document.getElementById('o-' + path).classList.add('active');
      });
      if (this.active.split('/').length > 1 && this.active.split('/')[1] !== 'blog') {
        this.submenuProductActive = true;
      } else if (this.active.split('/')[1] === 'blog') {
        this.submenuAboutActive = true;
      }
    }
  }

  openNav(): void {
    document.body.classList.toggle('noscroll', true);
    document.getElementById('myNav').style.width = '100%';
  }

  closeNav(): void {
    document.body.classList.toggle('noscroll', false);
    document.getElementById('myNav').style.width = '0%';
  }

  toggleProductSubMenu(): void {
    document.getElementById('p-plus-minus').classList.add('spin');
    setTimeout(() => {
      document.getElementById('p-plus-minus').classList.remove('spin');
      this.submenuProductActive = !this.submenuProductActive;
    }, 300);
  }

  toggleAboutSubMenu(): void {
    document.getElementById('a-plus-minus').classList.add('spin');
    setTimeout(() => {
      document.getElementById('a-plus-minus').classList.remove('spin');
      this.submenuAboutActive = !this.submenuAboutActive;
    }, 300);
  }
}
