import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Message} from '../models/models';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {Email} from '../../assets/smtp';
import * as emailjs from 'emailjs-com';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) {
  }

  postMessage(message: Message): void {
    const templateParams = {
      fullname: message.firstname + ' ' + message.lastname,
      telephone: message.telephone,
      email: message.email,
      needs: message.product,
      description: message.description
    };
    emailjs.send('service_uffu0jd', 'template_uxddckp', templateParams, 'user_Ff4MG4AwqPi0XQv75WlDM')
      .then((resp) => {
        // successCallback();
        console.log('FIRE EMAIL SUCCESS!', resp.status, resp.text);
      }, (err) => {
        // failureCallback();
        console.log('FIRE EMAIL FAILED...', err);
      });
  }
}
