import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Message, Translations} from '../../models/models';
import {validateEmail, validateTelephone, validateValidChars} from '../../helpers/helpers';
import {ContactService} from '../../services/contact.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-c-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() lang: string;
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() telephone: string;
  @Input() email: string;
  @Input() productsTitle: string;
  @Input() website: string;
  @Input() mobile: string;
  @Input() hosting: string;
  @Input() desc: string;
  @Input() remaining: string;
  @Input() send: string;
  @Input() reset: string;
  @Input() validationTranslations: string;
  @Input() editable: any;
  contactForm: FormGroup;
  productForm: FormGroup;
  validationTranslationsMapped: Translations;

  constructor(private contactService: ContactService, private router: Router, private formBuilder: FormBuilder) { }

  submitForm(): void {
    if (this.contactForm.valid && this.productForm.valid) {
      this.contactService.postMessage(this.buildMessage());
      localStorage.setItem('message', JSON.stringify(this.buildMessage()));
      this.router.navigateByUrl(this.lang + '/thank-you');
    } else {
      this.validateAllFormFields(this.contactForm);
      this.productForm.markAllAsTouched();
    }
  }

  private buildMessage(): Message {
    return {
      firstname: this.contactForm.get('firstname').value,
      lastname: this.contactForm.get('lastname').value,
      telephone: this.contactForm.get('telephone').value,
      email: this.contactForm.get('email').value,
      product: this.productForm.get('product').value,
      description: this.contactForm.get('description').value,
    };
  }

  resetForm(): void {
    this.contactForm.reset();
    this.productForm.reset();
    this.createForm();
  }

  ngOnInit(): void {
    this.validationTranslationsMapped = JSON.parse(this.validationTranslations);
    this.createForm();
  }

  private createForm(): void {
    this.contactForm = this.formBuilder.group({
      firstname: ['', {validators: [Validators.required, validateValidChars]}],
      lastname: ['', {validators: [Validators.required, validateValidChars]}],
      telephone: ['', {validators: [Validators.required, validateTelephone]}],
      email: ['', {validators: [Validators.required, validateEmail]}],
      description: ['', {validators: [Validators.required]}],
    });
    this.productForm = this.formBuilder.group({
      product: ['', Validators.required]
    });
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
      else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
