import {createAction, props} from '@ngrx/store';
import {Translations} from '../../../models/models';

export const changeLanguage = createAction(
  '[Lang Guard] Change Language',
  props<{ language: string }>()
);

export const loadTranslations = createAction(
  '[Lang Guard] Load Translations',
);

export const loadTranslationsSuccess = createAction(
  '[Lang Guard] Load Translations Success',
  props<{ translations: Translations }>()
);

export const loadTranslationsFailed = createAction(
  '[Lang Guard] Load Translations Failed',
);
