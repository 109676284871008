import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-c-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent {

  @Input()
  role: string;

  @Input()
  name: string;

  @Input()
  text: string;

  @Input()
  editable: any;

  constructor() { }

}
