import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() header;
  @Input() paragraph;
  // tslint:disable-next-line:variable-name
  @Input() _editable: any;

  constructor() { }

  ngOnInit(): void {
  }

}
