import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-article-title',
  templateUrl: './article-title.component.html',
  styleUrls: ['./article-title.component.scss']
})
export class ArticleTitleComponent implements OnInit {

  @Input() title: string;
  @Input() secondary: boolean;
  @Input() editable: any;

  @Input() @HostBinding('class.text--inline') inline = false;

  builtTitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
