import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Translations} from '../../models/models';
import {Store} from '@ngrx/store';
import {GlobalState} from '../../state/state';

@Component({
  selector: 'app-thanky-you-container',
  templateUrl: './thanky-you-container.component.html',
})
export class ThankyYouContainerComponent implements OnInit {
  translations: Observable<Translations>;
  lang: Observable<string>;

  constructor(private store: Store<GlobalState>) { }

  ngOnInit(): void {
    this.translations = this.store.select(state => state.app.translations);
    this.lang = this.store.select(state => state.app.language);
  }

}
