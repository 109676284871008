import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RadioElement, Translations} from '../../../../models/models';
import {getErrorMessageRadio, getErrorMessages} from '../../../../helpers/helpers';
import {TranslatePipe} from '../../../../pipes/translate.pipe';

@Component({
  selector: 'app-c-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true
    }
  ]
})
export class InputRadioComponent {

  @Input()
  label: string;

  @Input()
  options: RadioElement[];

  @Input()
  formGroup: FormGroup;

  @Input() translations: Translations;

  constructor(private translatePipe: TranslatePipe) { }

  onChange: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: string): void {
  }

  showError(): string {
    return getErrorMessageRadio(this.formGroup.get('product'), this.translatePipe, this.translations);
  }
}
