import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-c-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss']
})
export class ButtonSecondaryComponent {

  @Input()
  public label: string;

  @Input()
  public isDisabled: string;

  constructor() { }

  blur(element): void {
    element.blur();
  }

}
