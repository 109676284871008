import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent {

  constructor() { }

}
