import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appStoryblok]'
})
export class StoryblokDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('appStoryblok') appStoryblok: string;

  constructor(private el: ElementRef) {

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    if (typeof this.appStoryblok === 'undefined') {
      return;
    }

    const options = JSON.parse(this.appStoryblok.replace('<!--#storyblok#', '').replace('-->', ''));

    this.el.nativeElement.setAttribute('data-blok-c', JSON.stringify(options));
    this.el.nativeElement.setAttribute('data-blok-uid', options.id + '-' + options.uid);
  }

}
