import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-c-button-primary',
  templateUrl: './button-primary.component.html',
  styleUrls: ['./button-primary.component.scss']
})
export class ButtonPrimaryComponent {

  @Input()
  public label: string;

  @Input()
  public isDisabled: boolean;

  constructor() { }

  blur(element): void {
    element.blur();
  }
}
