import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-c-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  @Input() imgSrc: any;
  @Input() markupContent: string;
  @Input() editable: any;

  constructor(private renderer: Renderer2, public sanitizer: DomSanitizer) {
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');
  }

}
