import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './pages/home/home.component';
import {HeaderComponent} from './components/header/header.component';
import {MainStageComponent} from './components/main-stage/main-stage.component';
import {SocialButtonsComponent} from './components/social-buttons/social-buttons.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {MediaComponent} from './components/media/media.component';
import {ContentComponent} from './components/content/content.component';
import {FormComponent} from './components/form/form.component';
import {InputTextComponent} from './components/form/elements/input-text/input-text.component';
import {InputCheckboxComponent} from './components/form/elements/input-checkbox/input-checkbox.component';
import {ButtonPrimaryComponent} from './components/buttons/button-primary/button-primary.component';
import {ButtonSecondaryComponent} from './components/buttons/button-secondary/button-secondary.component';
import {TextareaComponent} from './components/form/elements/textarea/textarea.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputRadioComponent} from './components/form/elements/input-radio/input-radio.component';
import {ContentRightComponent} from './components/content-right/content-right.component';
import {FooterComponent} from './components/footer/footer.component';
import {SocialButtonsWhiteComponent} from './components/social-buttons-white/social-buttons-white.component';
import {InViewportModule} from 'ng-in-viewport';
import * as AppReducer from './state/app/app.reducers';
import {InfoComponent} from './components/info/info.component';
import {NavOverlayComponent} from './components/nav-overlay/nav-overlay.component';
import {ProductsComponent} from './components/products/products.component';
import {TitleComponent} from './components/title/title.component';
import {HttpClientModule} from '@angular/common/http';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import {ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {SvgIconsModule} from '@ngneat/svg-icon';
import {GlobalState} from './state/state';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './state/app/app.effects';
import { TranslatePipe } from './pipes/translate.pipe';
import {LangChangerComponent} from './components/lang-changer/lang-changer.component';
import { LazyLoadingDirective } from './directives/lazy-loading.directive';
import { ArticleComponent } from './components/article/article.component';
import { ArticleTextComponent } from './components/article/elements/article-text/article-text.component';
import { ArticleTitleComponent } from './components/article/elements/article-title/article-title.component';
import { ArticleImageComponent } from './components/article/elements/article-image/article-image.component';
import {appCloseIcon} from './svg/close';
import {appCloseHoverIcon} from './svg/close-hover';
import {appInstagramIcon} from './svg/instagram';
import {appMailIcon} from './svg/mail';
import {appMenuIcon} from './svg/menu';
import {appMenuHoverIcon} from './svg/menu-hover';
import {appMinusIcon} from './svg/minus';
import {appPlusIcon} from './svg/plus';
import { ThankyYouContainerComponent } from './pages-containers/thanky-you-container/thanky-you-container.component';
import { LinkComponent } from './components/link/link.component';
import {appRightArrowIcon} from './svg/right-arrow';
import {appRightArrowInvertIcon} from './svg/right-arrow-invert';
import { StoryblokDirective } from './directives/storyblok.directive';
import { PageComponent } from './components/page/page.component';
import {DynamicModule} from 'ng-dynamic-component';
import {StoryblokService} from './services/storyblok.service';
import { GridComponent } from './components/grid/grid.component';
import {appShockedIcon} from './svg/shocked';
import {IconComponent} from './components/icon/icon.component';
import { ColorTeaserComponent } from './components/color-teaser/color-teaser.component';
import { AuthorComponent } from './components/author/author.component';
import { BlogArticleComponent } from './components/blog-article/blog-article.component';

const reducers: ActionReducerMap<GlobalState> = {
  app: AppReducer.reducer
};

const effects = [
  AppEffects,
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MainStageComponent,
    SocialButtonsComponent,
    MediaComponent,
    ContentComponent,
    FormComponent,
    InputTextComponent,
    InputCheckboxComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    TextareaComponent,
    InputRadioComponent,
    ContentRightComponent,
    FooterComponent,
    SocialButtonsWhiteComponent,
    InfoComponent,
    NavOverlayComponent,
    ProductsComponent,
    TitleComponent,
    LangChangerComponent,
    ThankYouComponent,
    TranslatePipe,
    LazyLoadingDirective,
    ArticleTextComponent,
    ArticleComponent,
    ArticleImageComponent,
    ArticleTitleComponent,
    ThankyYouContainerComponent,
    LinkComponent,
    StoryblokDirective,
    PageComponent,
    GridComponent,
    IconComponent,
    ColorTeaserComponent,
    AuthorComponent,
    BlogArticleComponent,
  ],
  imports: [
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(
      reducers
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SvgIconsModule.forRoot({
      icons: [
        appCloseIcon,
        appCloseHoverIcon,
        appInstagramIcon,
        appMailIcon,
        appMenuIcon,
        appMenuHoverIcon,
        appMinusIcon,
        appPlusIcon,
        appRightArrowIcon,
        appRightArrowInvertIcon,
        appShockedIcon
      ],
    }),
    DynamicModule.withComponents([
      PageComponent,
      TitleComponent,
      HeaderComponent,
      MainStageComponent,
      ContentComponent,
      ContentRightComponent,
      ProductsComponent,
      FooterComponent,
      InfoComponent,
      FormComponent,
      ArticleComponent,
      ArticleImageComponent,
      ArticleTextComponent,
      ArticleTitleComponent,
      LangChangerComponent,
      GridComponent,
      LinkComponent,
      IconComponent,
      ColorTeaserComponent,
      BlogArticleComponent
    ]),
    ReactiveFormsModule,
    InViewportModule,
    HttpClientModule
  ],
  providers: [TranslatePipe, StoryblokService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
