import {Component, Input, OnInit} from '@angular/core';
import {Models} from '../../models/models';
import {Router} from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @Input()
  image: any;

  @Input()
  link: any;

  @Input()
  title: string;

  @Input()
  paragraph: string;

  @Input()
  editable: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navTo(link: any): void {
    window.location.href = `${link.cached_url}`;
  }
}
