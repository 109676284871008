<div class="product" data-aos="fade-up" data-aos-delay="100" data-test="products"
     [appStoryblok]="editable">
    <span class="product-image skeleton" data-test="products-img">
      <img src="{{ image.filename }}" alt="product-img" loading="lazy" (click)="navTo(link)">
    </span>
  <div class="product-content" (click)="navTo(link)">
    <h2 data-test="products-title">{{ title }}</h2>
    <p data-test="products-paragraph">{{ paragraph }}</p>
  </div>
</div>

