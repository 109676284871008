import {Component, Input, OnInit} from '@angular/core';
import {Message, Translations} from '../../models/models';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  @Input() translations: Translations;
  @Input() lang: string;
  message: Message = {firstname: '', lastname: '', telephone: '', email: '', description: '', product: ''};

  constructor() { }

  ngOnInit(): void {
    this.message = JSON.parse(localStorage.getItem('message'));
  }

}
