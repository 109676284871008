<div class="main-stage" [appStoryblok]="editable">
  <div class="image-container skeleton" [ngClass]="{'normal' :!purpleVariant}">
    <img src="{{image ? image.filename : 'website-building.png' }}" class="image" alt="mainstage image">
    <div class="info-container" *ngIf="!purpleVariant">
      <span style="display: block" data-aos="fade-right" data-aos-delay="0" [innerHTML]="sanitizer.bypassSecurityTrustHtml(markupInfo)"></span>
    </div>
    <div class="purple-container" *ngIf="purpleVariant">
      <strong class="purple-label">{{ label }}</strong>
      <strong class="purple-title">{{ title }}</strong>
    </div>
  </div>
</div>
