export interface Models {
  title: string;
  paragraph: string;
  image: string;
  link: string;
}

export interface RadioElement {
  label: string;
  value: string;
  controlName: string;
}

export interface Message {
  firstname: string;
  lastname: string;
  telephone: string;
  email: string;
  product: string;
  description: string;
}

export interface Translations {
  [name: string]: string;
}

export enum Languages {
  en,
  de
}
