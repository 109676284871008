<div class="c-input-checkbox--wrapper">
  <label for="checkbox-wrapper" class="checkbox-label">{{ label }}</label>
  <div id="checkbox-wrapper">
    <span *ngFor="let option of options">
      <label class="container">{{ option.label }}
        <input type="checkbox">
        <span class="checkmark"></span>
      </label>
    </span>
  </div>
</div>
