<div class="c-info constrainer" data-test="info" [appStoryblok]="editable">
  <div class="c-info--image" data-test="info-image">
    <img src="../../../assets/david-jitter.gif" alt="..." loading="lazy">
  </div>
  <div class="c-info--about">
    <h4 data-test="info-role">{{ role }}</h4>
    <h1 data-test="info-name">{{ name }}</h1>
    <p data-test="info-text">
      {{ text }}
    </p>
  </div>
</div>
