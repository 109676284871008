import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  constructor() {}

  @Input()
  label: string;

  @Input()
  url: any;

  @Input()
  inverted: boolean;

  ngOnInit(): void {
  }

  setIcon(): string{
    if (this.inverted){
      return '../../../assets/icons/right-arrow-invert.svg';
    }else{
      return '../../../assets/icons/right-arrow.svg';
    }
  }
}
