import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Components} from '../../components';
import {StoryblokService} from '../../services/storyblok.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  story = { content: null, name: '' };
  components = Components;

  constructor(private storyblokService: StoryblokService, private route: ActivatedRoute) {
    window.storyblok.init();
    window.storyblok.on(['change', 'published'], () => {
      location.reload(true);
    });
  }

  navigateToTcPilatus(): void {
    window.open('http://tc-pilatus.ch', '_blank');
  }

  navigateToMedcheck(): void {
    window.open('https://med-checker.web.app', '_blank');
  }

  ngOnInit(): void {
    this.storyblokService.getStory(`${this.route.snapshot.paramMap.get('lang')}/${this.route.snapshot.routeConfig.path}`,
      { version: environment.cmsConnect })
      .then(data => this.story = data.story);
  }
}
