import {Component, Input, OnInit} from '@angular/core';
import {ArticleImageComponent} from '../article/elements/article-image/article-image.component';
import {ArticleTextComponent} from '../article/elements/article-text/article-text.component';
import {ArticleTitleComponent} from '../article/elements/article-title/article-title.component';

@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss']
})
export class BlogArticleComponent {
  @Input() contents: any;
  @Input() editable: any;
  @Input() image: any;
  @Input() name: string;
  @Input() date: string;
  componentsArticle = { articleImage: ArticleImageComponent, articleText: ArticleTextComponent, articleTitle: ArticleTitleComponent };
}
