import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-social-buttons-white',
  templateUrl: './social-buttons-white.component.html',
  styleUrls: ['./social-buttons-white.component.scss']
})
export class SocialButtonsWhiteComponent {

  constructor() { }

}
