<div class="constrainer">
  <div class="color-teaser" [appStoryblok]="editable" data-aos="zoom-in-down" data-aos-duration="650">
    <div class="color-teaser__header">
      <h1 class="color-teaser__header-title" data-test="color-teaser-title">{{ title }}</h1>
    </div>
    <div class="color-teaser__image1">
    </div>
    <div class="color-teaser__text" [innerHTML]="text" data-test="color-teaser-text"></div>
    <div class="color-teaser--block">
    </div>
  </div>
</div>

