<div class="c-footer" data-test="footer" [appStoryblok]="editable">
  <div class="content">

    <div class="about-us" data-test="footer-about">
      <div>
        <h2>{{ aboutTitle }}</h2>
        <p>{{ aboutText }}</p>
        <div class="about-us--details">
          <h4>{{ aboutLocation }}</h4>
          <p>Buochs</p>
          <h4>{{ aboutQuestion }}</h4>
          <p>gataweb@outlook.com</p>
          <h4>{{ aboutTelephone }}</h4>
          <p>076 576 67 23</p>
        </div>
      </div>
    </div>

    <div class="latest-updates" data-test="footer-updates">
      <div>
      <h2>{{ updateTitle }}</h2>
      <div class="latest-updates--details">
        <div class="date-block">
          <p>
            4 <br>
            SEP
          </p>
        </div>
        <p class="update-text">Updated color pallet</p>
      </div>
      <div class="latest-updates--details">
        <div class="date-block">
          <p>
            3 <br>
            SEP
          </p>
        </div>
        <p class="update-text">Created Gataweb website</p>
      </div>
      </div>
    </div>

    <div class="socials" data-test="footer-socials">
      <div>
        <h2>{{ socialsTitle }}</h2>
        <p>{{ socialsText }}</p>
        <app-c-social-buttons-white></app-c-social-buttons-white>
      </div>
    </div>
  </div>
  <p class="copyright" data-test="footer-copyright">© 2020 Gataweb. All Rights Reserved.</p>
</div>
