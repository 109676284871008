import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Translations} from '../../models/models';

@Component({
  selector: 'app-c-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() active: string;
  @Input() translations: Translations;
  @Input() lang: string;
  @Input() productsLabel: string;
  @Input() websitesLabel: string;
  @Input() mobileLabel: string;
  @Input() hostingLabel: string;
  @Input() aboutLabel: string;
  @Input() blogLabel: string;
  @Input() contactLabel: string;
  @Input() isMainstageActive: boolean;

  @Input() editable: any;

  constructor() { }

  ngOnInit(): void {
    if (this.active) {
      this.active.split('/').forEach((path) => {
        document.getElementById(path).classList.add('active');
      });
    }
  }

  openMobileMenu(): void {
    if (!document.getElementById('hamburger').classList.contains('is-active')) {
      document.getElementById('hamburger').classList.add('is-active');
    } else {
      document.getElementById('hamburger').classList.remove('is-active');
    }
  }
}
