<div class="c-input-radio--wrapper">
  <form [formGroup]="formGroup">
    <label for="radio-wrapper" class="radio-label">{{ label }}</label>
    <div id="radio-wrapper">
    <span *ngFor="let option of options">
      <label class="container" [ngClass]="{ 'has-error': (formGroup.invalid && formGroup.touched) }">{{ option.label }}
        <input type="radio" [value]="option.value" [formControlName]="option.controlName">
        <span class="checkmark"></span>
      </label>
    </span>
    </div>
    <span *ngIf="formGroup.invalid && formGroup.touched" class="radio__error-message">
  {{ showError() }}
  </span>
  </form>
</div>
